<template>
  <index-content>
    <template #options>
      <nb-refresh @on-click="load" />
    </template>

    <div class="n-table-total-size">{{ totalText }}: {{ records.length }}</div>
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="app-id">{{ $t('label.appId') }}</th>
            <th class="process-id">{{ $t('label.processId') }}</th>
            <th class="app-name">{{ $t('label.appName') }}</th>
            <th class="app-home">{{ $t('label.appHome') }}</th>
            <th class="app-host">{{ $t('label.appHost') }}</th>
            <th class="ha">{{ $t('label.highAvailability') }}</th>
            <th class="coordinator">{{ $t('label.coordinator') }}</th>
            <th class="uptime">{{ $t('label.uptime') }}</th>
            <th class="n-table-timestamp">{{ $t('label.lastUpdate') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.appId }}</td>
            <td>{{ row.processId }}</td>
            <td>{{ row.appName }}</td>
            <td>{{ row.appHome }}</td>
            <td>{{ row.appHost }}</td>
            <td v-enums:HaStatus="row.haStatus"></td>
            <td>{{ row.coordinator | hyphen }}</td>
            <td>{{ row.uptimeText }}</td>
            <td>{{ row.updateDatetime | datetime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="n-table-total-size">{{ totalText }}: {{ records.length }}</div>
  </index-content>
</template>

<script>
import SystemView from './view';
import { forEach } from 'lodash-es';
import { formatFromNow } from '@/helpers/date';
import { fetch } from '@/api/system/system-application';

export default SystemView.extend({
  name: 'SystemApplication',
  data() {
    return {
      preload: true,
      totalText: this.$t('label.total'),
    };
  },

  methods: {
    doLoad() {
      return fetch();
    },

    parse(rows) {
      forEach(rows, r => {
        r.uptimeText = formatFromNow(r.startupDatetime);
      });
      this.records = rows;
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 1167px;

  th.app-id {
    width: 90px;
  }

  th.process-id {
    width: 130px;
  }

  th.app-home {
    min-width: 200px;
  }

  th.uptime {
    width: 140px;
  }
}
</style>
